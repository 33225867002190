import React from 'react'
import Aside from './Aside/Aside'
import Side from './Side/Side'

export default function Main(props) {
  return (
    <>
      <main className="bg-[#FBFDFD] flex flex-col justify-between items-center md:flex md:flex-row lg:flex lg:flex-row min-h-[100vh]">
        <Side />
        <Aside />
        {/* <div class="basis-1/3">03</div> */}
      </main>
    </>
  )
}
