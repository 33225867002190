import React from "react";

const Side = (props) => {
  return (
    <div className="basis-2/4 pt-16 flex flex-col justify-around items-center min-h-[100vh] flex-nowrap content-center text-center md:justify-evenly">
      <div className="pb-1">
        <h1 className="pb-4 text-main text-6xl text-[#031926]">
          New<br />Website
        </h1>
        <p className="text-uc text-[#031926] text-2xl">UNDER CONSTRUCTION</p>
      </div>

      <div className="md:hidden">
        <picture className="flex content-center justify-center">
          {/* <source srcSet='/images/pizza.webp' type='image/webp' /> */}
          <source srcSet={require("../../utilities/images/logo.JPG")} type="image/png" />
          <img 
            src={require('../../utilities/images/logo.JPG')}
            type="image/png"
            alt="Pizza"
            className="w-[60%]"
          />
        </picture>
      </div>

      <div className="w-[70%]">
        <ul className="flex flex-row justify-between">
          <li className="mx-2">
            <a
              href="https://www.facebook.com/pizzarte"
              target="_blank"
              rel="noreferrer"
            >
              <img 
                src={require('../../utilities/images/facebook_icon.png')}
                alt="Facebook" 
                className="w-[60%]"
              />
            </a>
          </li>
          <li className="mx-2">
            <a
              href="https://www.instagram.com/pizzarte"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require('../../utilities/images/instagram_icon.png')}
                alt="Instagram"
                className="w-[60%]"  
              />
            </a>
          </li>
          <li className="mx-2">
            <a
              href="https://www.gmail.com/pizzarte"
              target="_blank"
              rel="noreferrer"
            >
              <img 
                src={require('../../utilities/images/google_icon.png')}
                alt="Gmail"
                className="w-[60%]"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Side;
