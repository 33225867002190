import React from "react";

const Aside = (props) => {
  return (
    <div className="basis-2/4 hidden md:flex items-center">
      <picture className="flex content-center justify-center w-[100%]">
        {/* <source srcSet='/images/pizza.webp' type='image/webp' /> */}
        <source
          srcSet={require("../../utilities/images/logo.JPG")}
          type="image/png"
        />
        <img
          src={require("../../utilities/images/logo.JPG")}
          type="image/png"
          alt="Pizza"
          className="w-[60%]"
        />
      </picture>
    </div>
  );
};

export default Aside;
